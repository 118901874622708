//*******************************************************************************
// 1.0 - Neue Haas Grotesk Display
//*******************************************************************************

@font-face {
	font-family: 'Neue Haas Grotesk Display';
	// stylelint-disable-next-line declaration-colon-newline-after
	src: url('../fonts/NeueHaasGroteskDisplayW02-Reg.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Neue Haas Grotesk Display';
	// stylelint-disable-next-line declaration-colon-newline-after
	src: url('../fonts/NeueHaasGroteskDisplayW02-Medium.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Neue Haas Grotesk Display';
	// stylelint-disable-next-line declaration-colon-newline-after
	src: url('../fonts/NeueHaasGroteskDisplayW02-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

//*******************************************************************************
// 2.0 - Stempel Garamond
//*******************************************************************************

@font-face {
	font-family: 'Stempel Garamond';
	// stylelint-disable-next-line declaration-colon-newline-after
	src: url('../fonts/StempelGaramondW04-Roman.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Stempel Garamond';
	// stylelint-disable-next-line declaration-colon-newline-after
	src: url('../fonts/StempelGaramondW04-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
