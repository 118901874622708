// **************************************************
// Card
// **************************************************

@import "../../../scss/import";

// Style
.mod-card {
	display: grid;
	grid-gap: 24px;
	align-content: start;
	position: relative;

	// ##################################################

	&__author {
		position: absolute;
		left: -18px;
		top: 0;
		font-size: 11px;
		font-weight: 400;
		writing-mode: tb-rl;
		transform: rotate(180deg);
	}

	// ##################################################

	&__badge {
		background-color: $cgp_primary;
		color: $cgp_white;
		width: 100px;
		height: 100px;
		border-radius: 100%;
		position: absolute;
		top: 10px;
		right: 10px;
		font-size: 16px;
		font-weight: 400;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;
		pointer-events: none;
		padding: 10px;
		text-align: center;

		// Mobile
		@media #{$breakpoint-xs-only} {
			width: 60px;
			height: 60px;
			font-size: 12px;
			padding: 5px;
		}
	}

	// ##################################################

	&__inner {
		display: grid;
		grid-gap: 16px;
		position: relative;

		&:hover {
			.mod-card__overlay-content {
				opacity: 1;
				left: 0;
				pointer-events: auto;
			}
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			grid-gap: 12px;
		}
	}

	// ##################################################

	&__header {
		display: grid;
		grid-gap: 10px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			grid-gap: 4px;
		}
	}

	// ##################################################
	// Link for the whole card

	&__overlay-link {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: 2;
	}

	// ##################################################
	// Wrapper for card media elements

	&__media {
		width: 100%;
		padding-bottom: 122.67%;
		position: relative;
		overflow: hidden;
	}

	// ##################################################

	&__overlay-content {
		@extend %content;
		padding: 56px 20px 20px 20px;
		background-color: rgba($cgp_body, 0.9);
		display: flex;
		flex-direction: column;
		justify-content: center;
		opacity: 0;
		left: -200px;
		pointer-events: none;
		@include transition(opacity 0.3s ease, left 0.3s ease);

		&-btn {
			text-align: right;
			padding-right: 12px;
			margin-top: 12px;
		}
	}

	// ##################################################

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	// ##################################################
	// Selector for every parent component of card

	&__footer {
		position: relative;
		z-index: 3;
	}

	// ##################################################

	&__button {
		grid-auto-flow: column;
		justify-content: end;
		text-align: right;
	}

	// ##################################################

	&__price {
		s {
			margin-right: 16px;
		}
	}
}

