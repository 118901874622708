// ##################################################
// Main banner
// ##################################################

@import "../../../scss/import";

// Style
.main-banner {
	max-width: $header_width;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 604px;
	grid-gap: 80px;
    align-items: center;
    margin: 0 auto;

    // Tablet L and down
    @media #{$breakpoint-md-down} {
		grid-template-columns: 1fr 400px;
		grid-gap: 60px;
	}

    // Tablet P and down
    @media #{$breakpoint-sm-down} {
		grid-template-columns: 1fr;
		grid-gap: 40px;
	}
}
