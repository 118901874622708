// **************************************************
// Footer
// **************************************************

@import "../../../scss/import";

// Style
.footer {
	padding: 56px 0 80px 0;
	position: relative;
	width: 100%;
	font-size: 14px;
	z-index: 2;

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 32px 0 40px 0;
	}

	&:before {
		background-color: $cgp_border;
		@extend %content;
		height: 2px;
		max-width: 1288px;
		width: 100%;
		right: 0;
		margin: auto;
	}

	&__content {
		display: grid;
		grid-template-columns: 480px 1fr;
		grid-gap: 0 80px;

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			grid-template-columns: 1fr;
			grid-gap: 40px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			grid-gap: 24px;
		}

		.body-text {
			line-height: 1.25em;
		}
	}

	// **************************************************

	// Footer menu
	&__menu {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;

		// Mobile
		@media #{$breakpoint-xs-only} {
			grid-template-columns: 1fr 1fr;
			grid-gap: 20px;
		}
	}

	// **************************************************

	ul {
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
	}

	// **************************************************

	&__title {
		font-size: 18px;
		font-weight: 400;
		margin-bottom: 24px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 8px;
		}
	}

	// **************************************************

	&__nav {
		li {
			display: block;
			margin-top: 5px;
		}

		a {
			color: $cgp_white;
			font-size: 16px;
			font-weight: 600;
			@include transition(color 0.3s ease);

			&:hover {
				color: $cgp_primary;
			}
		}
	}

	// **************************************************

	&__copyright {
		border-top: 1px solid rgba($cgp_border, 0.4);
		font-size: 14px;
		text-align: right;
		box-sizing: border-box;
		padding-top: 24px;
		margin-top: 60px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		// Tablet and down
		@media #{$breakpoint-sm-down} {
			text-align: left;
			margin-top: 27px;
		}

		.cookie-link {
			color: $cgp_white;
			margin-left: 130px;
			margin-right: auto;
			background: url("data:image/svg+xml,#{$cookie_icon}") no-repeat left / 12px auto;
			padding-left: 20px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				margin-left: 20px;
			}
		}

		a {
			@include transition(color 0.3s ease);

			&:hover {
				color: $cgp_primary;
			}
		}
	}

	// **************************************************

	.full-width-mobile {
		// Mobile
		@media #{$breakpoint-xs-only} {
			grid-column: 1 / span 2;
		}
	}

	.header-main__logo-text {
		// Mobile
		@media #{$breakpoint-xs-only} {
			width: 196px;
		}

		.#{$class_scrolled} & {
			width: 196px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				width: 196px;
			}
		}
	}
}

